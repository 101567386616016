// stylesheets
import '../scss/main.scss';

// vendors
import $ from 'jquery';
import 'bootstrap';
import 'bootstrap-select';

// custom components
import './components/flash_loader';
import './components/scroll_loader';
import './components/form';
import './components/poptorial';
import './components/fileinput_loader';

$(document).ready(function () {

    const $window = $(window);

    const onWindowResize = () => {
        $('.menu, .main').removeAttr('style'); // reset css
    };

    const onWindowScroll = () =>
    {

    };

    $(document).tooltip({
        'selector': '[data-toggle="tooltip"]',
    });

    $('select').selectpicker({
        style: ''
    });



    if ($('#business_accessibleSuppliers').length && $('#business_accessibleProductLines').length)
        import('./components/business_form').catch(error => 'An error occurred while loading the business form component');

    if ($('form[name="formula"]').length)
        import('./pages/formula').catch(error => 'An error occurred while loading the formula page code');

    $('.menu__list li:has(ul)').addClass('has-children');

    let path = window.location.pathname;
    let $link = $('.menu a[href="' + path + '"]');

    if ($link.length == 0) {
        let occurrence = path.split('/').length;
        for (let x = occurrence; x > 1; x--) {
            let tmp = path.substr(0, path.split('/', x).join('/').length);
            if ($('.menu a[href^="' + tmp + '"]:first').length) {
                $link = $('.menu a[href^="' + tmp + '"]:first');
                break;
            }
        }
    }

    $link.addClass('active');

    if ($link.parents('.has-children').length) {
        $link = $link.parents('.has-children');
        $link.addClass('active');
        $link.find('ul').show();
    }

    $('.has-children').on('click', function () {
        let $el = $(this);
        $el.toggleClass('active');
        $el.find('ul').slideToggle();
    });

    $('.form-float input').each(function () {
        $(this).toggleClass('filled', $(this).val().length > 0);
    });
    $(document).on('keyup', '.form-float input', function () {
        $(this).toggleClass('filled', $(this).val().length > 0);
    });

    $(document).on('click', '.panel-list .parent', function () {
        $(this).find('ul').slideToggle();
        $(this).toggleClass('active');
    });

    $('#menu-button').on('click', function () {
        let $el = $('.menu');
        $el.toggle();
        let mainWidth = $(window).width() < 992 ? '100%' : ($el.is(':visible') ? 'calc(100% - 250px)' : '100%');
        $('.main').css('width', mainWidth);
    });

    $('table thead th input[type="checkbox"]').on('change', function () {
        let $el = $(this);
        let $table = $el.closest('table');
        let $checkboxes = $table.find('tbody .row-selection');

        $checkboxes.prop('checked', $el.prop('checked'));
    });

    $(document).on('change', '.row-selection, table thead th input[type="checkbox"]', function () {
        let $el = $(this);
        let $table = $el.closest('table');
        let $checkboxes = $table.find('tbody .row-selection');
        let count = $checkboxes.filter(':checked').length;

        $('.js-count').text('(' + count + ' selected)');
    });

    $(document).on('click', 'a', function (e){
        $('#js-scroll-loader').css('display', 'block');
    });

    $(document).on('click', '.js-delete, .btn-outline-danger', function (e){
        e.stopPropagation();

        let $target = $(this);
        $.confirm({
            title: 'Verwijderen',
            content: 'Weet u zeker dat u deze wilt verwijderen?',
            buttons: {
                Ja: function () {
                    location.href = $target.attr('href');
                },
                Nee: function () {
                    $('#js-scroll-loader').css('display', 'none');
                }
            }
        });

        return false;
    });

    $('.notify').on('click', function (e) {
        e.stopPropagation();

        let $el = $(this);
        let id = $el.data('id');

        if (!$el.hasClass('notify--read')) {
            $.post('/notification/read', {'id': id}, function (response) {
                $el.addClass('notify--read')
            });
        }
    });

    let resizeTimer;
    $window.on('resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            onWindowResize();
        }, 100);
    });
    onWindowResize();

    $window.on('scroll', () => {
        onWindowScroll();
    });
    onWindowScroll();
});