import $ from 'jquery';

$(document).ready(() => {

    const bag = JSON.parse($('#tooltip-bag').html() || "{}");

    if ($.isEmptyObject(bag))
        return;

    let isRead = bag.isRead;

    $('#show-tool-tips').css('display', 'inline-block');

    $(document).poptorial({
        autoStart: !isRead,
        tooltips: bag.tooltips
    });

    for (const [key, tooltip] of bag.tooltips.entries()) {
        if (!tooltip.isRead) {
            $(document).poptorial('switch', key);
            break;
        }
    }

    $(document).on('poptorial.close', () => {
        if (!isRead) {
            $.post(bag.readByUrl, {
                alias: bag.alias
            });
            isRead = true;
        }
    });

    $('#show-tool-tips').on('click', () => {
        $(document).poptorial('start');
    });
});

$.poptorial = function (el, options) {

    // To reference this class from internal events and functions
    var base = this;

    // Access to jQuery and DOM versions of the element
    base.$el = $(el);
    base.el = el;

    // Add a reverse reference to the DOM object
    base.$el.data('poptorial', base);

    var $window = $(window);
    var currentTooltip = null;

    base.init = function () {
        base.options = $.extend({}, $.fn.poptorial.defaults, options);

        base.paused = !base.options.autoStart;

        // Validate given values from tooltips
        $.each(base.options.tooltips, function (index, value) {
            value.index = index;
            if (!value.title) console.log('Value "title" is missing at tooltip: ' + index);
            if (!value.content) console.log('Value "content" is missing at tooltip: ' + index);
        });

        if (!$('.pt__curtain').length)
            $('body').append('<div class="pt__curtain"></div>');

        if (!base.options.tooltips[0])
            return;

        currentTooltip = base.options.tooltips[0];

        if (base.options.autoStart)
            base.createTooltip(currentTooltip);

        $window.on('scroll resize', function () {
            if (!base.paused)
                base.updateLocation(currentTooltip);
        });

        $(document).on('click', '.pt__tooltip .pt__close', function () {
            base.switch('close');
        });

        $(document).on('click', '.pt__tooltip .pt__previous', function () {
            base.switch('prev');
        });

        $(document).on('click', '.pt__tooltip .pt__next', function () {
            base.switch('next');
        });

        $(document).on('keyup', function (e) {
            if (base.paused || e.keyCode != 32)
                return;

            base.switch('next');
        });

        $(document).on('click', '.pt__tooltip .pt__indicators span', function () {
            base.switch($(this).data('index'));
        });
    };

    base.switch = function(index) {
        var $el = $('#tooltip-' + currentTooltip.id);
        $($el).remove();

        var newIndex = index;
        if (typeof index != 'number') {
            if (['prev', 'next', 'close'].indexOf(index) !== -1) {
                switch (index) {
                    case 'prev':
                        newIndex = currentTooltip.index - 1;
                        break;
                    case 'next':
                        newIndex = currentTooltip.index + 1;
                        break;
                    case 'close':
                        base.paused = true;
                        break;
                }
            } else {
                console.log('Poptorial error: you can enter a number or choose prev, next, close');
            }
        }

        if (typeof base.options.tooltips[newIndex] != 'undefined') {
            $('[data-pt-z]').each(function () {
                var zIndex = $(this).attr('data-pt-z');
                $(this).removeAttr('data-pt-z');
                $(this).css('z-index', zIndex);
            });
            base.paused = false;
            currentTooltip = base.options.tooltips[newIndex];
            base.createTooltip(currentTooltip);
        } else {
            base.undim();
            $(document).trigger('poptorial.close');
        }
    };

    base.dim = function(tooltip) {
        var scroll;

        if (typeof tooltip.selector != 'undefined' && tooltip.selector != null) {
            var $el = $(tooltip.selector);
            $el.attr('data-pt-z', $el.css('z-index'));
            $el.css('z-index', '9999998');
            scroll = $el.offset().top - ($window.height() / 4);
        } else {
            scroll = 0;
        }

        $('html, body').animate({
            scrollTop: scroll,
        }, 500);

        $('.pt__curtain').animate({opacity: '.6'}, 200);
    };

    base.undim = function() {
        $('.pt__curtain').animate({opacity: '0'}, 200);
        $('[data-pt-z]').each(function () {
            var zIndex = $(this).attr('data-pt-z');
            $(this).removeData('pt-z');
            $(this).css('z-index', zIndex);
        });
    };

    base.createTooltip = function(tooltip) {

        var hasSelector = typeof tooltip.selector != 'undefined' && tooltip.selector != null;

        if (hasSelector)
            tooltip.target = $(tooltip.selector);

        if (hasSelector && !tooltip.target.length) {
            console.log('No element was found with: ' + tooltip.selector);
            base.switch('next');
            return;
        }

        tooltip.id = Math.floor(Math.random() * 100000);

        base.dim(tooltip);

        var indicators = '';
        if (base.options.indicators) {
            if (base.options.tooltips.length >= 2 && base.options.tooltips.length <= 5) {
                for (var x = 0; x < base.options.tooltips.length; x++)
                    indicators += '<span' + (x == tooltip.index ? ' class="active"' : '') + ' data-index="' + x + '"></span>';
            } else if (base.options.tooltips.length >= 6)
                indicators = '<div>' + (tooltip.index + 1) + '</div>';
        }

        $('body').append(
            '<div class="pt__tooltip" id="tooltip-' + tooltip.id + '" data-placement="' + tooltip.placement + '">' +
            '<div class="pt__close">&times;</div>' +
            '<div class="pt__header">' + tooltip.title + '</div>' +
            '<div class="pt__content">' + tooltip.content + '</div>' +
            '<div class="pt__footer">' +
            (base.options.tooltips.length >= 2 ? '<span class="pt__previous">' + (tooltip.index != 0 ? base.options.txtPrevious : base.options.txtClose) + '</span>' : '') +
            '<div class="pt__indicators">' +
            indicators +
            '</div>' +
            '<span class="pt__next">' + (tooltip.index != base.options.tooltips.length - 1 ? base.options.txtNext : base.options.txtClose) + '</span>' +
            '</div>' +
            '</div>'
        );

        tooltip.initialized = true;
        base.updateLocation(tooltip);
    };

    base.updateLocation = function(tooltip) {
        var $el = $('#tooltip-' + tooltip.id);

        if (!$el.is(':visible'))
            return;

        if (typeof tooltip.target != 'undefined') {
            tooltip.targetOffset =  tooltip.target.offset();

            switch (tooltip.placement) {
                case 'right':
                    tooltip.xDistance = tooltip.target.outerWidth() + tooltip.targetOffset.left + 16;
                    tooltip.yDistance = tooltip.target.outerHeight() / 2 + tooltip.targetOffset.top;
                    break;
                case 'left':
                    tooltip.xDistance = tooltip.targetOffset.left - 16;
                    tooltip.yDistance = tooltip.target.outerHeight() / 2 + tooltip.targetOffset.top;
                    break;
                case 'top':
                    tooltip.xDistance = tooltip.target.outerWidth() / 2 + tooltip.targetOffset.left;
                    tooltip.yDistance = tooltip.targetOffset.top - 16;
                    break;
                case 'bottom':
                    tooltip.xDistance = tooltip.target.outerWidth() / 2 + tooltip.targetOffset.left;
                    tooltip.yDistance = tooltip.target.outerHeight() + tooltip.targetOffset.top + 16;
                    break;
                default:
                    return;
            }
        } else {
            tooltip.xDistance = $window.outerWidth() / 2;
            tooltip.yDistance = $window.outerHeight() / 2;
        }

        switch (tooltip.placement) {
            case 'right':
                tooltip.yDistance -= $el.outerHeight() / 2;
                break;
            case 'left':
                tooltip.yDistance -= $el.outerHeight() / 2;
                tooltip.xDistance -= $el.outerWidth();
                break;
            case 'top':
                tooltip.xDistance -= $el.outerWidth() / 2;
                tooltip.yDistance -= $el.outerHeight();
                break;
            case 'bottom':
                tooltip.xDistance -= $el.outerWidth() / 2;
                break;
            default:
                tooltip.yDistance -= $el.outerHeight() / 2;
                tooltip.xDistance -= $el.outerWidth() / 2;
                break;
        }

        if (tooltip.xDistance < 0)
            tooltip.xDistance = 0;

        if (tooltip.yDistance < 0)
            tooltip.yDistance = 0;

        if (tooltip.xDistance + $el.outerWidth() > $window.width())
            tooltip.xDistance = $window.width() - $el.outerWidth();

        if (tooltip.yDistance + $el.outerHeight() > $window.height() + $window.scrollTop())
            tooltip.yDistance = $window.height() + $window.scrollTop() - $el.outerHeight();

        $el.css({'transform': 'translate3d(' + tooltip.xDistance + 'px, ' + tooltip.yDistance + 'px, 0px)'});
    };

    base.start = function() {
        base.switch(0);
    };

    base.resume = function() {
        base.paused = false;
        base.createTooltip(currentTooltip);
    };

    base.stop = function() {
        base.switch('close');
    };

    // Run initializer
    base.init();
};

$.fn.poptorial = function (options, params) {
    return this.each(function () {
        var data = $(this).data('poptorial');
        if (!data)
            (new $.poptorial(this, options));
        else {
            data[options](params);
        }
    });
};

$.fn.poptorial.defaults = {
    autoStart: true,
    indicators: true,
    txtClose: 'Close',
    txtPrevious: 'Previous',
    txtNext: 'Next',
    tooltips: []
};