import $ from "jquery";

$('.js-scroll-loader').each((index, el) => {
    const $el = $(el);
    const $window = $(window);

    const url = $el.data('scrollUrl');
    const limit = parseInt($el.data('scrollLimit'), 10);

    if (url == null) {
        console.log("data-scroll-url not defined");
        return;
    }

    if (isNaN(limit) || limit == null) {
        console.log("data-scroll-limit not defined");
        return;
    }

    let currentRequest = null;
    let offset = limit;
    let search, sort;
    let isEndReached = false;

    $window.on('scroll', () => {
        if (!$el.is(':visible'))
            return ;

        if ($window.scrollTop() >= $el.offset().top + $el.outerHeight() - $window.innerHeight() - 150 && currentRequest == null) {
            fetch();
        }
    });

    $('.js-search').on('keyup', (event) => {
        search = $(event.currentTarget).val();
        fetch(true);
    });

    $('select.js-sort').on('change', (event) => {
        sort = $(event.currentTarget).val();
        fetch(true);
    });

    $('.table--datatable thead th[data-column]').on('click', (event) => {
        const $el = $(event.currentTarget);
        const column = $el.data('column');
        const isActive = $el.hasClass('active');

        $('.table--datatable thead th.active').removeClass('active');
        $el.toggleClass('active');

        sort = column + '_' + (isActive ? (sort.includes('desc') ? 'asc' : 'desc') : 'asc');

        fetch(true);
    });

    const fetch = (reset = false) => {
        if (reset) {
            offset = 0;
            isEndReached = false;
        }

        if (isEndReached)
            return;

        $('#js-scroll-loader').css('display', 'block');

        currentRequest = $.ajax({
            type: 'POST',
            url: url,
            data: {
                limit: limit,
                offset: offset,
                search: search,
                sort: sort
            },
            beforeSend: () => {
                if (currentRequest != null)
                    currentRequest.abort();
            },
            success: (response) => {
                if (response.length == 0) {
                    isEndReached = true;
                } else {
                    if (reset)
                        $el.html(response);
                    else
                        $el.append(response);

                    offset += limit;
                }

                $('#js-scroll-loader').hide();
                currentRequest = null;
            }
        });
    };
});