document.addEventListener('DOMContentLoaded', () => {
    const prototypes = document.querySelectorAll(`[data-prototype]`);

    prototypes.forEach(prototype => {

        const form = prototype.closest(`form`);

        if (!form) {
            console.error(`parent form not found for prototype: #${prototype.id}`);
            return;
        }

        const buttonAdd = form.querySelector(`.prototype-add`);

        if (!buttonAdd) {
            console.error(`No element found with class .prototype-add in form with name: ${form.name}`);
            return;
        }

        buttonAdd.addEventListener('click', e => {
            e.preventDefault();

            let template = prototype.dataset.prototype;
            const index = parseInt(prototype.dataset.index);

            template = template.replace(/__name__/g, index);

            prototype.dataset.index = index + 1;
            prototype.innerHTML += template;
        });
    });
});