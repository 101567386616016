import $ from "jquery";
import 'jquery-confirm';

$(document).ready(() => {
    const flashes = JSON.parse($('#flash-bag').html() || "{}");
    Object.entries(flashes).map(([label, messages]) => {
        for (const message of messages) {

            let params = {
                theme: 'modern',
                title: '',
                content: message,
                type: label,
                draggable: false,
            };

            if (typeof message == 'object') {
                params = {...params, ...message};

                if (message.buttons != 'undefined' && typeof message.buttons == 'object') {
                    Object.keys(message.buttons).forEach(button => {
                        if (typeof message.buttons[button].target != 'undefined') {
                            params.buttons[button].action = () => {
                                window.location.href = message.buttons[button].target;
                            };
                        }
                    });
                }
            }

            $.alert(params);
        }
    });
});